.header-img-mobile {
  height: 75px;
  margin-left: 20px;
  margin-top: 20px;
}

.smi-div-container {
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-end;
}

.smi-div {
  margin-right: 10px;
}

.mobile-social-media-icons {
  height: 25px;
  margin-left: 5px;
}

#mobile-instagram-icon {
  margin-left: 10px;
}

.mobile-nav-container {
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.mobile-nav-buttons {
  display: flex;
  flex-direction: row;
}

.mobile-nav-button {
  width: 100%;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 7px;
  background-color: #ec3b83;
  display: flex;
  color: white;
  justify-content: center;
}

.mobile-nav-button > span {
  color: inherit;
  font-size: 11px;
  font-weight: 600;
  font-family: 'Open Sans';
}

.mobile-nav-button:active {
  background-color: white;
  color: #ec3b83;
}

.mobile-options-bar {
  min-height: 29px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: black;
  display: flex;
  justify-content: space-between;
}

.mobile-options-page,
.logout-span {
  font-size: 15px;
  color: white;
  font-weight: 600;
  margin-right: 5px;
  margin-left: 10px;
}

.logout-span:active {
  color: #ec3b83;
}

.mobile-subscribe-options {
  margin-right: 10px;
}

.mobile-options-bar > div {
  margin-top: 3px;
}

.mobile-options-page-active {
  font-size: 15px;
  color: #ec3b83;
  font-weight: 600;
  margin-right: 5px;
  margin-left: 10px;
}

#subscribe-option-mobile {
  margin-right: 10px;
}

.mobile-options-page:active,
.mobile-options-page-active:active {
  color: #ec3b83;
}

.tagline-mobile {
  margin-top: 50px;
  text-align: center;
}

.tagline-mobile-gif {
  height: 50px;
  margin-left: auto;
  margin-right: auto;
}

.podcast-logo-mobile-header {
  margin-top: 12vw;
  margin-left: 3vw;
  max-width: 50vw;
}

/* .podcast-logo-mobile-header-hidden {
  margin-top: -6vw;
  margin-left: 3vw;
  width: 20vw;
  visibility: hidden;
} */

.podcast-video-buttons {
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
}

.mobile-video-button {
  width: 40vw;;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: -4vw;
  margin-bottom: 10px;
  padding: 10px;
 
  display: flex;
  color: white;
  justify-content: center;
}

#twitter-icon {
    margin-right: -3px;
  
}