@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.issues-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.issues-container-mobile {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  max-width: 100vw;
  /* height: 50vw; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
  border-radius: 7px; 
  /* flex-wrap: wrap; */
}

.covers {
  width: 40vw;
  margin-top: 5vw;
  margin-bottom: 5vw;
  /* transition: 1s; */
  box-shadow: 1.5vw 1.5vw #ec3b83;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.covers-desktop {
 width: 20vw;
 margin-top: 5vw;
  margin-bottom: 5vw;
  /* transition: 1s; */
  box-shadow: 0.8vw 0.8vw #ec3b83;
  display: flex;
  justify-content: center;
  align-items: baseline;
  
}

.covers-desktop:hover {
  cursor: pointer;
}

.covers:hover {
  cursor: pointer;
}

.issue-captions {
  font-size: 2vw;
  font-family: 'Open Sans';
  color: white;
  font-weight: bold;
  text-align: center;
  
}

.cover-divs {
  margin-bottom: 30px;
}

.covers-supplement {
  width: 40vw;
  margin-top: 5vw;
  margin-bottom: 5vw;
  /* transition: 1s; */
  box-shadow: 1.5vw 1.5vw #ec3b83;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.covers-desktop-supplement {
 width: 20vw;
 margin-top: 5vw;
  margin-bottom: 5vw;
  /* transition: 1s; */
  box-shadow: 0.8vw 0.8vw #ec3b83;
  display: flex;
  justify-content: center;
  align-items: baseline;
  
}

.issue-page-spacer {
  height: 3vw;
}

/* .covers-desktop-supplement:hover {
  cursor: pointer;
}

.covers-supplement:hover {
  cursor: pointer;
} */