@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.articles-container {
  margin-left: 30px;
  margin-right: 30px;
  max-width: 400px;
}

.articles-box {
  width: 100%;
  /* background-color: white; */
  color: black;
  border-radius: 7px;
}

.articles-box > p {
  padding: 10px;
  color: white;
  
}

.mobile-article-title {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-top: 98px;
}

.article-title {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-top: 98px;
}

.article-title:hover {
  cursor: default;
}

.mobile-article-title:hover {
  cursor: default;
}

.articles-title:hover {
  cursor: default;
}

.article-list-item {
  font-family: Caslon;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  
}

.article-list-item > a {
  color: #ffffff;
  cursor: pointer;
  transition: 0.2s;
  font-family: Caslon;
}

.article-list-item > a:hover {
  color: #ec3b83;
}

/* .article-list-item > a:visited {
  color: #ffffff;
} */

.article-item-divider {
  color: #ec3b83;
}

.guides-title {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  margin-top: 20px;
  font-family: 'Open Sans';
  margin-bottom: 30px;
}

.guides-title:hover {
  cursor: default;
}