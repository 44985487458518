.mobile-video-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 3vw;

}

.desktop-video-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 1vw;
    
}

.vascular-slide {
    width: 60vw;
    margin-bottom: 10vw;
}

.vascular-slide:hover {
    cursor: pointer;
}

.video-main-title {
    text-align: center;
    color: white;
    font-weight: bold;
    margin-top: 5vw;
    margin-bottom: 1vw;
    width: 100vw;
    background-color: #ec3b83;
}

.video-one-title {
    text-align: center;
    font-size: 1.3em;
    color: white;
    font-weight: bold;
    margin-bottom: 10vw;
}

.video-one-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video-one-container {
    margin-top: 10vw;
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-bottom: 5vw;
}

.go-back-button-background-video {
    background-color: #ec3b83;
    text-align: center;
    border-radius: 1vw;
    display: flex;
    justify-content: center;
    height: 3vw;
    width: 25vw;
  }

  .go-back-button-background-video:hover {
      cursor: pointer;
  }

.go-back-button-video {
    color: #ffffff;
    
    text-shadow: none;
    font-size: 1.5vw;
    margin-top: auto;
    margin-bottom: auto;
  }