@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans';
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: default;
  text-decoration: none;
}

.header {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  min-height: 50%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
}

.header-img:hover {
  cursor: pointer;
}

.page-wrapper {
  background-color: white;
}

.lorem-ipsum {
  margin-left: 5px;
  margin-bottom: 100px;
  margin-left: 15px;
  margin-right: 15px;
  line-height: 1.3;
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.lorem-ipsum:hover {
  cursor: default;
}

.lorem-ipsum > span {
  color: #ec3b83;
}

.form-button {
  background-color: #ec3b83;
  color: white;
  border: none;
  border-radius: 7px;
  font-size: 11px;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.form-button:hover {
  cursor: pointer;
}

.form-button:active {
  background-color: white;
  color: #ec3b83;
}

.body-div {
  min-height: 100vh;
}

.error-message {
  display: flex;
  justify-content: center;
}

.message-box {
  border-radius: 7px;
  width: 200px;
  background-color: #ec3b83;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.message-box > p {
  margin-right: 10px;
  margin-left: 10px;
}

.email-links {
  color: #ec3b83;
}

.email-links:hover {
  cursor: pointer;
}

p:hover {
  cursor: default;
}

.tagline {
  display: flex;
  justify-content: center;
  margin-top: 250px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}

.tagline:hover,
.tagline > span:hover {
  cursor: default;
}

.page-graphic-container {
  width: 300px;
}

.page-graphic-box {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  height: 335px;
  background-color: white;
  border-radius: 7px;
}

.page-graphic {
  width: 250px;
  height: 315px;
}

.mobile-title {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-top: 50px;
}

.offer-img {
  width: 350px;
  margin-top: 25px;
  margin-left: -20px;
}

.divider {
  height: 20px;
  margin-bottom: 20px;
  background-image: linear-gradient(187deg, #ec3b83, 40%, black);
}

.divider-mobile {
  height: 10px;
  margin-bottom: 10px;
  background-color: black;
}

hr {
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  height: 1px;
  background-color: black;
}



.swiper {
  margin-top: 20px;
  height: 420px;
  width: 840px;
}

.swiper-mobile {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 420px;
  width: 100%;
}

.mobile-slides {
  text-align: center;
}

.slides {
  text-align: center;
}

.maintenance-message {
  text-align: center;
  color: white;
}