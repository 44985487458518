.page-container {
  display: flex;
  flex-direction: column;
}

.home-container {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.current-issue-container {
  margin-right: 10px;
  width: 30vw;
}

.current-issue-box {
  height: 100%;
  border-radius: 7px;
  background-color: white;
}

.content-titles {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 12px;
}

.content-titles:hover {
  cursor: default;
}

.issue-img {
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 30px;
  max-width: 170px;
  min-height: 240px;
  transition: 1s;
  box-shadow: 10px 10px #ec3b83;
}

.featured-content-container {
  width: 70vw;
}

.featured-content-box {
  /* max-width: inherit;
  
  /* display: flex;
  justify-content: flex-end; */
  height: 481px;
  border-radius: 7px;
  background-color: white;
  display: flex;
  justify-content: start;
}

.twitter-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 30px;
}

.twitter-box {
  background-color: white;
  border-radius: 7px;
  width: 300px;
  min-height: 520px;
  display: flex;
  justify-content: center;
  margin-right: -26px;
}

#about-issue > p {
  padding: 10px;
  line-height: 1.3;
  font-size: 14px;
  font-weight: bold;
  color: #36454f;
  font-family: 'Open Sans';
}

.about-issue > p > span {
  color: #ec3b83;
}

.about-issue > p > span:hover {
  cursor: pointer;
}

.offer-home {
  margin-left: 50px;
  width: 40vw;
  margin-bottom: 50px;
}

.offer-home:hover {
  cursor: pointer;
}

.offer-twitter {
  margin-top: 30px;
  margin-left: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.home-ewma-ad-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 50px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.home-ewma-ad {
  height: 350px;
}

.home-ewma-ad:hover {
  cursor: pointer;
}
