.register-page-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.register-container {
  max-width: 400px;
  padding-left: 30px;
  padding-right: 30px;
}

.register-title,
.register-continue {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
}

.register-title {
  margin-top: 98px;
}

.register-title:hover {
  cursor: default;
}

.register-box {
  margin-top: 13px;
  cursor: default;
  display: flex;
  justify-content: center;
}

.register-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.register-form > input {
  margin-top: 10px;
  height: 2rem;
  width: 65vw;
  max-width: 300px;
  font-size: 16px;
  background-color: whitesmoke;
  text-overflow: ellipsis;
  border: solid 2px black;
  border-radius: 7px;
}

.register-input:focus,
.register-form > input:focus {
  outline: none;
  border-color: #ec3b83;
}

.register-input::placeholder {
  font-style: italic;
  color: grey;
}

#register-top-input {
  margin-top: 20px;
}

#register-bottom-input {
  margin-bottom: 12px;
}

.register-select {
  border-radius: 4.5px;
  border: solid 2px black;
  outline: none;
  margin-top: 10px;
  height: 2.2rem;
  margin-right: 2px;
  margin-left: 2px;
  font-size: 16px;
  border: none;
  font-style: italic;
  background-color: whitesmoke;
  color: slategray;
}

.register-select > option {
  font-style: normal;
  font-size: 16px;
  color: black;
  border: none;
  outline: none;
}

.register-select-mobile {
  border-radius: 4.5px;
  border: none;
  outline: none;
  margin-top: 10px;
  height: 2.5rem;
  margin-right: 2px;
  margin-left: 2px;
  font-size: 16px;
  border: none;
  font-style: italic;
  background-color: whitesmoke;
  color: slategray;
}

.register-select-mobile > option {
  font-style: normal;
  font-size: 16px;
  color: black;
  border: none;
  outline: none;
}

#select-title {
  display: none;
}

.register-select:focus,
.register-select-mobile:focus {
  outline: none;
  border: solid 2px #ec3b83;
}

#register-continue {
  margin-top: 12px;
}

.privacy-checkbox-container {
  margin-top: 10px;
  margin-bottom: 7px;
  display: flex;
  justify-content: baseline;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
}

INPUT[type='checkbox']:focus {
  outline: 1px solid rgba(0, 0, 0, 0.2);
}

INPUT[type='checkbox'] {
  background-color: white;
  border-radius: 2px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
}

INPUT[type='checkbox']:checked {
  background-color: #ec3b83;
  background: #ec3b83
    url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==')
    3px 3px no-repeat;
}

.checkbox-label {
  font-size: 16px;
  font-weight: 600;
  color: white;
}
.checkbox-label-mobile {
  margin-top: 2px;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.privacy-span-mobile {
  color: #ec3b83;
  font-size: 16px;
  font-weight: 600;
}

.privacy-span-mobile:hover {
  cursor: pointer;
}

.privacy-span {
  color: #ec3b83;
  font-size: 16px;
  font-weight: 600;
}

.privacy-span:hover {
  cursor: pointer;
}

.registering-container {
  text-align: center;
}

.registering {
  height: 25px;
  margin-left: auto;
  margin-right: auto;
}

.registered-question {
  margin-top: 20px;
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

#reg-select-firefox {
  color: #bfbfbf;
}

.checkbox-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.offer {
  margin-top: 100px;
  max-height: 350px;
}

.offer-mobile {
  margin-top: 50px;
  max-height: 500px;
}

