.podcast-container {

display: flex;
flex-direction: column;
flex-wrap: nowrap;
}

.podcast-image-coming-soon {
    width: 90vw;
}

.podcast-coming-line {
    margin-top: 5vw;
    margin-bottom: 5vw;
    margin-left: 3vw;
    margin-right: 3vw;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 4vw;
}