.footer-body {
  background-color: black;
  margin-top: 100px;
  height: 380px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-body > p:hover {
  cursor: default;
}

.img-address {
  margin-top: 50px;
  font-size: medium;
  font-weight: 600;
}

.img-address:hover {
  cursor: default;
}

.mobile-footer-img {
  height: 50px;
  margin-bottom: 20px;
}

.footer-img {
  height: 70px;
  margin-bottom: 20px;
}

.footer-img:hover {
  cursor: pointer;
}

.footer-body > div:not(.img-address) {
  margin-top: 100px;
}

.footer-text {
  color: whitesmoke;
  line-height: 1.4;
}

.footer-span {
  background-color: transparent;
  font-size: medium;
  color: whitesmoke;
  font-weight: 600;
  transition: 0.2s;
}

.footer-span:hover {
  color: #ec3b83;
  cursor: pointer;
}

.related-journals {
  font-size: medium;
  font-weight: 600;
}

.related-journals:hover {
  cursor: default;
}

.footer-bottom-margin {
  height: 60px;
  background-color: black;
}

.mobile-footer-bottom-margin {
  height: 30px;
  background-color: black;
}

.footer-bottom {
  margin-top: 50px;
  height: 50px;
  background-color: black;
  position: relative;
}

.mobile-footer-bottom {
  margin-top: 30px;
  height: 50px;
  background-color: black;
  position: relative;
}

.footer-bottom > span,
.mobile-footer-bottom > span {
  margin: 0;
  margin-left: 10px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: medium;
  color: whitesmoke;
  font-weight: 600;
}

.footer-bottom > span:hover {
  cursor: default;
}

.footer-smi {
  padding: 20px;
  margin-left: -20px;
}

.footer-smi > a > img {
  max-height: 25px;
  margin-right: 5px;
}

.footer-smi > a > img:hover {
  cursor: pointer;
}

#instagram-icon-footer {
  margin-left: 5px;
}

#twitter-icon {
  
    margin-right: -1px;
  
}