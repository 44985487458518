.accounts-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  min-width: 300px;
}

.accounts-options {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 12px;
  text-align: center;
}

.change-input {
  height: 2rem;
  width: 65vw;
  max-width: 300px;
  font-size: 16px;
  border: solid 2px black;
  border-radius: 7px;
  background-color: whitesmoke;
  text-overflow: ellipsis;
}

#top-change-input {
  margin-top: 50px;
  margin-bottom: 10px;
}

#middle-change-input {
  margin-bottom: 10px;
}

#bottom-change-input {
  margin-bottom: 30px;
}

.change-input:focus {
  outline: none;
  border-color: #ec3b83;
}

.change-input::placeholder {
  font-style: italic;
  color: slategray;
}

.change-container {
  max-width: 400px;
  padding-left: 30px;
  padding-right: 30px;
}

.change-box {
  margin-top: 13px;
  margin-bottom: 13px;
  cursor: default;
  max-height: 15rem;
  display: flex;
  justify-content: center;
}

.details-box {
  width: 400px;
  min-width: 300px;
  height: 250px;
  background-color: white;
  border-radius: 7px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.details-box > p {
  margin-top: 30px;
  margin-left: 30px;
  line-height: 1.3;
  font-size: 14px;
  font-weight: bold;
  color: #36454f;
  font-family: 'Open Sans';
}

.details-box > p > span {
  color: #ec3b83;
}

.accounts-page-container {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
