.subscribe-container {
  margin-left: 30px;
  margin-right: 30px;
  max-width: 400px;
}

.subscribe-box {
  width: 100%;
  /* background-color: white; */
  color: black;
  border-radius: 7px;
}

.subscribe-box > p {
  padding: 10px;
  color: white;
  font-family: 'Open Sans';
}

.subscribe-title {
  background-color: #ec3b83;
  color: white;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-top: 98px;
}

.subscribe-title:hover {
  cursor: default;
}
