.events-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
 justify-content: center;
  font-size: 10vw;
}

.events-container-desktop {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
 justify-content: center;
  font-size: 5vw;
}

.first-banner {
  /* background-color: #ec3b83; */
  
  width: 100%;
  height: 10vw;
  margin-top: 5vh;
  text-align: center;
  font-size: 0.5em;
  font-weight: 900;
  color: white;
  margin-bottom: 7vw;
}

.masterseries {
  font-style: italic;
  color: #ec3b83;
}

.intro-banner {
  background-color: #ec3b83;
  margin-bottom: 3vw;
}

.intro-banner > p {
  text-align: center;
  font-size: 0.3em;
  color: white;
  margin-left: 1vw;
  margin-right: 1vw;
  font-weight: 900;
}

.next-event-section {
  width: 100%;
  height: 10vw;
  margin-top: 5vh;
  text-align: center;
  font-size: 0.4em;
  font-weight: 900;
  color: white;
  margin-bottom: 3vw;
  display: flex;
  flex-direction: column;
}

.countdown {
  color: #ec3b83;
  font-size: 1em;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.countdown:hover {
  cursor: default;
}

.next-event-title > p {
  color: white;
  text-align: center;
  font-size: 0.4em;
  
}

.vascular-speakers {
 margin-left: 5vw;
  margin-right: 5vw;
  display: flex;
  justify-content: center;
}

.vascular-speakers > img {
  width: 70vw;
    
}

.next-event-date {
  color: #ec3b83;
  font-weight: 500;
  font-size: 2.5em;
}

.register-here {
  margin-top: 10vw;
  width: 100vw;
  background-color: #ec3b83;
  text-align: center;
  font-size: 0.4em;
  font-weight: 900;
  color: white;
  
}

.agenda-title {
  margin-top: 10vw;
  width: 100vw;
  background-color: #ec3b83;
  text-align: center;
  font-size: 0.4em;
  font-weight: 900;
  color: white;
}

.agenda-title:hover {
  cursor: default;
  background-color: #ffffff;
  color: #ec3b83;
}

.register-here:hover {
  cursor: pointer;
  background-color: #ffffff;
  color: #ec3b83;
}

.agenda-list-container {
  font-size: 0.5em;
  color: #ffffff;
  list-style-type: none;
  font-weight: bold;
  text-align: center;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
}

.agenda-list-container:hover {
  cursor: default;
}

.sponsored-by {
  margin-top: 10vh;
  margin-bottom: 10vh;
  font-size: 0.5em;
  color: white;
  text-align: center;
}

#sponsors {
  display: flex;
  justify-content: center;
  
}

.logo-image {
  margin-top: 3vh;
  max-width: 30vh;
  margin-bottom: 1vh;
}

.logo-image:hover {
  cursor: pointer;
}

.logos {
  display: flex;
  flex-direction: column;
  
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  
}

.auditorium-vascular {
  width: 99vw;
  /* margin-left: 1vw;
  margin-right: 1vw; */
}

.auditorium-container {
  display: flex;
  justify-content: center;
}

.auditorium-image-and-text {
  position: relative;
}

.auditorium-image-and-text:hover {
  cursor: pointer;
}

.live-now {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
width: 50vw;
}

.melin-gif {
  position: absolute;
  top: 3.1vw;
  right: 9vw;
  width: 47vw;
  /* border-radius: 1vw; */
}

.watch {
  color: #ec3b83;
}

.auditorium-button {
  height: 10px;
  width: 10px;
}

.early {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2.5vw;
    font-weight: bold;
    text-align: center;
    text-shadow: 0.1vw 0.1vw #000000;
}

.early:hover {
  cursor: default;
}

.early-span {
  color: #ffffff;
  text-shadow: 0.1vw 0.1vw #000000;
}

.date-line {
  color: #ec3b83;
  text-shadow: none;
}

.auditorium-sponsor-booths {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin-bottom: 7vw;
  font-weight: bold;
  /* width: 100vw; */
}

#auditorium-link {
  transition: 0.5s;
}

#sponsor-booth-link {
  transition: 0.5s;
}

#auditorium-link:hover {
cursor: pointer;
color: #ec3b83;
}

#sponsor-booth-link:hover {
  cursor: pointer;
  color: #ec3b83;
}

.go-back-button-background {
  background-color: #ec3b83;
  text-align: center;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  height: 3vw;
}

.go-back-button-background:hover {
  cursor: pointer;
}

.go-back-button {
  color: #ffffff;
  text-shadow: none;
  font-size: 0.5em;
  margin-top: auto;
  margin-bottom: auto;
}

.go-back-button-booths-background {
  background-color: #ec3b83;
  text-align: center;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  height: 3vw;
  margin-top: 3vw;
  width: 60%;
}

.go-back-button-booths {
  color: #ffffff;
  text-shadow: none;
  font-size: 1em;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
}

.go-back-button-booths-background:hover {
  cursor: pointer;
}

.sponsor-choice-container {
 display: flex;
 justify-content: center;
 flex-direction: column;
 align-items: center;
 width: 100vw;
}

.choose-sponsor-message {
  text-align: center;
  margin-top: 10vw;
  margin-bottom: 10vw;
  color: #ffffff;
  font-weight: bold;
  font-size: 3vw;
  width: 80vw;
}

.ms-span-sponsor-booths {
  color: #ec3b83;
  font-style: italic;
}

.sponsor-one-container {
  position: relative;
  text-align: left;
  color: #ec3b83;
  
  font-size: 1.3vw;
  
}

.sponsor-one-container:hover {
  cursor: default;
}

.aroa-booth-text {
  position: absolute;
  top: 3vw;
  right: 16px;
  margin-left: 50vw;
  font-size: 1vw;
}

.urgo-booth-text {
  position: absolute;
  top: 3vw;
  right: 16px;
  margin-left: 50vw;
  font-size: 1vw;
  
}

.sponsor-one-booth-image {
  width: 100vw;
}

.go-back-button-sponsor-choice-background {
  background-color: #ec3b83;
  text-align: center;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  height: 5vw;
  margin-top: 3vw;
  width: 40%;
}

.go-back-button-sponsor-choice-background:hover {
  cursor: pointer;
}

.go-back-button-sponsor-choice {
  color: #ffffff;
  text-shadow: none;
  font-size: 2vw;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
}

.booth-sponsor-links {
  font-weight: bold;
  color: #ec3b83;
}

.booth-sponsor-links:hover {
  cursor: pointer;
}